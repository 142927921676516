import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import {
  FORGOT_PASSWORD,
  SET_PASSWORD,
  SIGN_UP,
} from '../../../constants/routes';
import { setLoading, setMessage } from '../../../store/settings';

import { getTokens, verifyToken } from '../../../helpers/tokens';
import { getEnvUserPoolClients } from '../../../constants/clients';
import {
  getCurrentSession,
  adminLogIn,
  logInAll,
  logOutAll,
} from '../../../helpers/auth';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomTextField from '../../../components/CustomTextField';
import GoogleButton from '../../../components/GoogleButton';
import TextWithLink from '../../../components/TextWithLink';

const ADMIN_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_ADMIN_REDIRECT_SIGNIN_URL;

function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const client = useSelector((state) => state.client);

  const [idToken, setIdToken] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const redirectToApplication = (refreshToken) => {
    window.location.assign(`${client.signInUrl}?refreshToken=${refreshToken}`);
  };

  useEffect(() => {
    const isSuper = idToken?.['custom:SuperUser'] === 'true';
    if (client.clientId === 'admin' && !idToken === null && !isSuper) {
      dispatch(
        setMessage({
          type: 'error',
          text: 'User must be an Orchestrate Admin to access the Admin Dashboard',
        })
      );
    }
  }, [idToken, client, dispatch]);

  useEffect(() => {
    const findUser = async () => {
      dispatch(setLoading(true));
      try {
        let _idToken = null;
        const session = await getCurrentSession(client.clientId);
        if (session) {
          _idToken = await verifyToken(session.idToken, 'id', client.clientId);
        }
        setIdToken(_idToken ? { ..._idToken } : null);
      } catch (error) {
        console.warn(error);
        setIdToken(null);
      } finally {
        dispatch(setLoading(false));
      }
    };
    if (client.clientId) {
      findUser();
    }
  }, [client.clientId, dispatch]);

  const handleAdminLogin = async () => {
    const result = await adminLogIn(email, password);
    const session = await getCurrentSession(client.clientId);

    let _idToken = null;
    if (session) {
      _idToken = await verifyToken(session.idToken, 'id', client.clientId);
    }
    const isSuper = _idToken?.['custom:SuperUser'] === 'true';

    if (!isSuper) {
      setIdToken(_idToken);
      return;
    }

    const url = new URL(ADMIN_REDIRECT_SIGNIN_URL);
    for (const [key, value] of Object.entries(result)) {
      url.searchParams.append(key, value.refreshToken);
    }
    window.location.assign(url);
  };

  const handleLogIn = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    try {
      // Log in to Admin Dashboard
      if (client.clientId === 'admin') {
        handleAdminLogin();
        return;
      }

      // Log in to all other apps
      const result = await logInAll(email, password, client.clientId);
      if ('refreshToken' in result) {
        redirectToApplication(result.refreshToken);
      } else if (result.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(push(`${SET_PASSWORD}?client_id=${client.clientId}`, result));
      } else {
        dispatch(setMessage({ type: 'error', text: 'Error logging in' }));
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleContinueToApplication = (e) => {
    e.preventDefault();
    if (client.clientId === 'admin') {
      let refreshTokens = {};
      for (const clientId in getEnvUserPoolClients()) {
        const { refreshToken } = getTokens(clientId);
        refreshTokens[clientId] = { refreshToken };
      }
      const url = new URL(ADMIN_REDIRECT_SIGNIN_URL);
      for (const [key, value] of Object.entries(refreshTokens)) {
        url.searchParams.append(key, value.refreshToken);
      }
      window.location.assign(url);
    } else {
      const { refreshToken } = getTokens(client.clientId);
      redirectToApplication(refreshToken);
    }
  };

  const handleLogOut = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    try {
      await logOutAll();
      setIdToken(null);
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isSuper = idToken?.['custom:SuperUser'] === 'true';

  if (idToken?.email) {
    return (
      <CustomCardContent title='Welcome back!'>
        <CustomTextField
          id='login-email'
          label='Email'
          value={idToken?.email}
          icon={
            <InputAdornment position='start'>
              <EmailIcon />
            </InputAdornment>
          }
          inputProps={{ readOnly: true }}
        />
        <CustomButton
          onClick={handleContinueToApplication}
          disabled={client.clientId === 'admin' && !isSuper}
        >
          Continue to {client.domainName}
        </CustomButton>
        <CustomButton onClick={handleLogOut} variant='outlined'>
          Log out
        </CustomButton>
      </CustomCardContent>
    );
  } else {
    return (
      <CustomCardContent
        title='Log in with Orchestrate'
        subtitle={
          client.domainName && (
            <Typography variant='body1'>
              to continue to <strong>{client.domainName}</strong>
            </Typography>
          )
        }
      >
        <form onSubmit={handleLogIn}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CustomTextField
              id='login-email'
              label='Email'
              value={email}
              autoComplete='current-email'
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              icon={
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              }
            />
            <CustomTextField
              id='login-password'
              label='Password'
              value={password}
              autoComplete='current-password'
              onChange={(e) => setPassword(e.target.value)}
              icon={
                <InputAdornment
                  position='start'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setHidePassword((h) => !h)}
                >
                  {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </InputAdornment>
              }
              type={hidePassword ? 'password' : 'text'}
            />
            {client.clientId !== 'admin' && (
              <TextWithLink
                linkText='Forgot Password?'
                onClick={() =>
                  navigate(`/${FORGOT_PASSWORD}?client_id=${client.clientId}`)
                }
              />
            )}
            <Box sx={{ width: '100%', mt: 2 }} />
            <CustomButton>Log In</CustomButton>
            {client.clientId !== 'admin' && (
              <TextWithLink
                text='Need an account?'
                linkText='Sign Up'
                onClick={() =>
                  navigate(`/${SIGN_UP}?client_id=${client.clientId}`)
                }
              />
            )}
            <GoogleButton action='log in' />
          </Box>
        </form>
      </CustomCardContent>
    );
  }
}

export default LogIn;
