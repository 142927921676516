export const MANAGEMENT_APP_CLIENT_ID =
  process.env.REACT_APP_MANAGEMENT_APP_CLIENT_ID;
const DEMAND_APP_CLIENT_ID = process.env.REACT_APP_DEMAND_APP_CLIENT_ID;
const SOLAR_APP_CLIENT_ID = process.env.REACT_APP_SOLAR_APP_CLIENT_ID;
const ENERGYTRACER_APP_CLIENT_ID =
  process.env.REACT_APP_ENERGYTRACER_APP_CLIENT_ID;

const SOLAR_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_SOLAR_REDIRECT_SIGNIN_URL;
const SOLAR_REDIRECT_SIGNOUT_URL =
  process.env.REACT_APP_SOLAR_REDIRECT_SIGNOUT_URL;
const DEMAND_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_DEMAND_REDIRECT_SIGNIN_URL;
const DEMAND_REDIRECT_SIGNOUT_URL =
  process.env.REACT_APP_DEMAND_REDIRECT_SIGNOUT_URL;
const MANAGEMENT_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_MANAGEMENT_REDIRECT_SIGNIN_URL;
const MANAGEMENT_REDIRECT_SIGNOUT_URL =
  process.env.REACT_APP_MANAGEMENT_REDIRECT_SIGNOUT_URL;
const ENERGYTRACER_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_ENERGYTRACER_REDIRECT_SIGNIN_URL;
const ENERGYTRACER_REDIRECT_SIGNOUT_URL =
  process.env.REACT_APP_ENERGYTRACER_REDIRECT_SIGNOUT_URL;

export const CLIENTS = {
  [SOLAR_APP_CLIENT_ID]: {
    appName: 'solar',
    domainName: 'lightlevel.io',
    signInUrl: SOLAR_REDIRECT_SIGNIN_URL,
    signOutUrl: SOLAR_REDIRECT_SIGNOUT_URL,
  },
  [DEMAND_APP_CLIENT_ID]: {
    appName: 'demand',
    domainName: 'gridcap.io',
    signInUrl: DEMAND_REDIRECT_SIGNIN_URL,
    signOutUrl: DEMAND_REDIRECT_SIGNOUT_URL,
  },
  [MANAGEMENT_APP_CLIENT_ID]: {
    appName: 'management',
    domainName: 'orchestratehub.com',
    signInUrl: MANAGEMENT_REDIRECT_SIGNIN_URL,
    signOutUrl: MANAGEMENT_REDIRECT_SIGNOUT_URL,
  },
  [ENERGYTRACER_APP_CLIENT_ID]: {
    appName: 'energytracer',
    domainName: 'energytracer.com',
    signInUrl: ENERGYTRACER_REDIRECT_SIGNIN_URL,
    signOutUrl: ENERGYTRACER_REDIRECT_SIGNOUT_URL,
  },
  admin: {
    appName: 'admin',
    domainName: 'admin.orchestratehub.com',
  },
};

export const getEnvUserPoolClients = () => {
  let clients = {};
  for (const [clientId, client] of Object.entries(CLIENTS)) {
    if (client.signInUrl) {
      clients[clientId] = client;
    }
  }
  return clients;
};
