import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { logOutAll } from '../../../helpers/auth';

function LogOut() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('client_id');

  useEffect(() => {
    logOutAll();
    const navigateUrl = `/login${clientId ? `?client_id=${clientId}` : ''}`;
    navigate(navigateUrl);
  }, [clientId, logOutAll, navigate]);

  return <></>;
}

export default LogOut;
