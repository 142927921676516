import { CLIENTS, getEnvUserPoolClients } from '../constants/clients';
import { initAuth, refreshTokens } from './cognito';
import { setTokens, getTokens, removeTokens } from './tokens';

const ENV = process.env.REACT_APP_ENV;
const REGION = process.env.REACT_APP_REGION;
const UUID = process.env.REACT_APP_UUID;
const DOMAIN = `https://orchestrate-${ENV}-user-pool${
  UUID ? `-${UUID}` : ''
}.auth.${REGION}.amazoncognito.com`;

const getCurrentSession = async (clientId) => {
  const now = Math.round(new Date().getTime() / 1000);

  if (clientId === 'admin') {
    clientId = Object.keys(getEnvUserPoolClients())[0];
  }

  const tokens = getTokens(clientId);
  const { refreshToken, accessToken, idToken, exprTime } = tokens;

  // if refreshToken has not expired, return values from localStorage
  if (now < exprTime && accessToken) {
    return {
      accessToken,
      idToken,
      exprTime,
    };
  }

  if (refreshToken && clientId) {
    return await refreshTokens(refreshToken, clientId);
  }

  console.warn('No current session');
};

const logInAll = async (username, password, currentClientId) => {
  let returnPayload = {};

  // Log into currentClient first
  let client = CLIENTS[currentClientId];
  const response = await initAuth(
    username,
    password,
    currentClientId,
    client.appName
  );
  if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
    return response;
  } else {
    setTokens(response.AuthenticationResult, currentClientId);
    returnPayload = {
      accessToken: response.AuthenticationResult.AccessToken,
      idToken: response.AuthenticationResult.IdToken,
      refreshToken: response.AuthenticationResult.RefreshToken,
    };
  }

  // Log into all other clients
  let promises = [];
  for (const [clientId, client] of Object.entries(getEnvUserPoolClients())) {
    if (clientId === currentClientId) {
      continue;
    }
    promises.push(
      Promise.resolve(initAuth(username, password, clientId, client.appName))
    );
  }

  await Promise.all(promises).then(
    (responses) => {
      for (const response of responses) {
        setTokens(response.AuthenticationResult, response.clientId);
      }
    },
    (error) => {
      console.log(error);
    }
  );

  return returnPayload;
};

const adminLogIn = async (username, password) => {
  let returnPayload = {};

  // Log into all other clients
  let promises = [];
  for (const [clientId, client] of Object.entries(getEnvUserPoolClients())) {
    promises.push(
      Promise.resolve(initAuth(username, password, clientId, client.appName))
    );
  }

  await Promise.all(promises).then(
    (responses) => {
      for (const response of responses) {
        setTokens(response.AuthenticationResult, response.clientId);
        returnPayload[response.clientId] = {
          accessToken: response.AuthenticationResult.AccessToken,
          idToken: response.AuthenticationResult.IdToken,
          refreshToken: response.AuthenticationResult.RefreshToken,
        };
      }
    },
    (error) => {
      console.log(error);
    }
  );

  return returnPayload;
};

const logOutAll = async () => {
  for (const clientId in getEnvUserPoolClients()) {
    removeTokens(clientId);
  }
};

const authWithGoogle = async (client) => {
  const queryString = Object.entries({
    redirect_uri: client.signInUrl,
    response_type: 'code',
    client_id: client.clientId,
    identity_provider: 'Google',
  })
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  if (!window?.location) {
    return;
  }
  window.location.href = `${DOMAIN}/oauth2/authorize?${queryString}`;
};

const getLastVisitedClient = () => {
  return localStorage.getItem('lastVisitedClient');
};

const setLastVisitedClient = (clientId) => {
  localStorage.setItem('lastVisitedClient', clientId);
};

export {
  getCurrentSession,
  logInAll,
  adminLogIn,
  logOutAll,
  authWithGoogle,
  getLastVisitedClient,
  setLastVisitedClient,
};
