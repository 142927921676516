import React from 'react';

import Link from '@mui/material/Link';

function CustomLink(props) {
  const { children, onClick } = props;
  return (
    <Link
      variant='body2'
      underline={onClick ? 'hover' : 'none'}
      onClick={onClick}
      color='white'
      sx={{ cursor: 'pointer' }}
    >
      {children}
    </Link>
  );
}

export default CustomLink;
